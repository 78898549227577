import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useState} from 'react';
import styled from 'styled-components';

/**
 * A Drawer component that opens on user click.
 * @param open - Boolean state. If `true`, then the drawer opens.
 * @param onClose - Function should set the open state.
 * @param children - React children node.
 */

const DrawerBackdrop = styled.div`
    width: 100vw;
    height: 100vw;
    position: fixed;
    inset: 0;
    z-index: 50;
    background-color: rgba(0,0,0, 0.25);
`

const DrawerPopover = styled.div`
  width: 100%;
  max-width: 400px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index:51;
  overflow-y: auto;
  background-color: white;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    margin: 0 20px;
    border-bottom: 1px solid black;
    h2 {
      font-family: "General Sans";
    }
  }
  .body {
    height: calc(100% - 74px);
    display: flex;
    flex-direction: column;
  }
`

function Drawer({open, closeFnc, children}: any) {
  return open ?
    (<>
      <DrawerBackdrop onClick={closeFnc}></DrawerBackdrop>
      <DrawerPopover>
        <div className="heading">
          <h2>Cart</h2>
          <IconClose onClick={closeFnc}/>
        </div>
        <div className="body">
          {children}
        </div>
      </DrawerPopover>
    </>) :
    <></>
}

/* Use for associating arialabelledby with the title*/
Drawer.Title = Dialog.Title;

export {Drawer};

export function useDrawer(openDefault = false) {
  const [isOpen, setIsOpen] = useState(openDefault);

  function openDrawer() {
    setIsOpen(true);
  }

  function closeDrawer() {
    setIsOpen(false);
  }

  return [
    isOpen,
    openDrawer,
    closeDrawer,
  ];
}

export function IconClose({onClick}: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      style={{width: 20, height: 20, cursor:"pointer"}}
      onClick={onClick}
    >
      <title>Close</title>
      <line
        x1="4.44194"
        y1="4.30806"
        x2="15.7556"
        y2="15.6218"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <line
        y1="-0.625"
        x2="16"
        y2="-0.625"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 16 4.75)"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </svg>
  );
}
