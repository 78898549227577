import { Link } from "@remix-run/react";
import styled, { css } from "styled-components";
import { breakpoint, toRem } from "./styledUtilities";

export const Logo = styled(Link)`
    font-family: 'Faxi', sans-serif;
    color: black;
    text-decoration: none;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 11px;
    ${breakpoint(768, css`
        font-size: 33px;
        letter-spacing: 20px;
    `)}
    ${breakpoint(1366, css`
        font-size: ${toRem(33)};
        letter-spacing: ${toRem(20)};
    `)}
    &:hover, &:visited, &:active {
        color: black;
    }
`
export const NavLink = styled(Link)`
    font-family: 'Faxi', sans-serif;
    color: black;
    text-decoration: none;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    ${breakpoint(768, css`
        font-size: 63px;
    `)}
    ${breakpoint(1366, css`
        font-size: ${toRem(63)};
    `)}
    &:hover, &:visited, &:active {
        color: black;
    }
`
export const Hamburger = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 50px;
    div {
        height: 2px;
        width: 100%;
        background-color: black;
    }
`
export const CartIcon = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    img {
        width: 30px;
        height: auto;
    }
    span {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px; 
    }
`
export const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    .home-link {
        text-decoration: none;
    }
    ${breakpoint(768, css`padding: 15px 30px`)}
    ${breakpoint(1366, css`
    padding: ${toRem(15)} ${toRem(30)};
    `)}
`
