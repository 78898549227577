import { Link } from "@remix-run/react"
import styled, { css } from "styled-components"
import { breakpoint, toRem } from "~/styles/styledUtilities";

const FooterContainer = styled.footer`
    padding: 0 24px 15px;
    .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    h2 {
        font-family: 'Faxi';
        display: inline;
        font-size: 35px;
        line-height: 35px;
        letter-spacing: 22px;
        width: 150px;
        margin-bottom: 45px;
    }
    .socials {
        display: flex;
        flex-direction: column;
        gap: 4px;
        a {
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: black;
            text-decoration: none;
        }
    }
    .other-links {
        div {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
        } 
        a {
            color: black;
            text-decoration: none;
            margin-right: 18px;
        }
        span {
            margin-right: 18px;
            a {
                margin: 0;
            }
        }
    }
    ${breakpoint(768, css`
        padding: 0 30px 15px;
    `)}
    ${breakpoint(1200, css`
        padding: 0 100px 75px;
    `)}
    ${breakpoint(1366, css`
        padding: 0 ${toRem(100)} ${toRem(75)};
        h2 {
            font-size: ${toRem(35)};
            line-height: ${toRem(35)};
            letter-spacing: ${toRem(22)};
            margin-bottom: ${toRem(45)};
            width: ${toRem(150)};
        }
        .socials {
            a {
                font-size: ${toRem(18)};
                line-height: ${toRem(24)};
            }
        }
        .other-links {
            div {
                font-size: ${toRem(14)};
                line-height: ${toRem(24)};
            }
        }
    `)}
`

export const Footer = () => {
    let currDate = new Date();
    let year = currDate.getFullYear();
    return (
        <FooterContainer>
            <div className="row">
                <h2>Lepa Vida</h2>
                <div className="socials">
                    <Link to='https://www.instagram.com/lepavidawinery/'>
                        Instagram
                    </Link>
                    <Link to="https://www.facebook.com/lepavidavino/">
                        Facebook
                    </Link>
                    <Link to="https://www.linkedin.com/company/lepa-vida-wine/">
                        LinkedIn
                    </Link>
                </div>
            </div>
            <div className="row other-links">
                <div>
                    <span>Made by <Link to="https://www.akeo.com">Akeo</Link> with &hearts;</span>
                    <Link to={'/terms-of-service'}>Terms of service</Link>
                    <Link to={'/privacy-policy'}>Privacy policy</Link>
                    <Link to={'/return-policy'}>Return policy</Link>
                </div>
                <div>
                    Lepa Vida boutique winery © {year} All rights reserved.
                </div>
            </div>
        </FooterContainer>
    )
}