import {ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState} from 'react';
import {ProductFormContainer} from '~/styles';
import {IProductVariantModel} from '~/utils/types/ourWines';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useMatches, useFetcher } from '@remix-run/react';
import styled from 'styled-components';
import { Money } from '@shopify/hydrogen';
import toast from 'react-hot-toast'
import { Paragraph } from '~/utils/helpers/mapModulesToUi';
import { spawn } from 'isbot';

interface IProductFormProps {
  variants: IProductVariantModel[];
  showPrice: boolean; //shows Price if true, if false shows ALK percentage
  info: string;
  buttonText: string; //by default is add to cart,
  featuredColor: string; //prop drilling, sue me.
  isProductPage: boolean;
  isWineBox: boolean;
}

export const ProductForm: React.FC<IProductFormProps> = ({
  variants,
  showPrice,
  info,
  buttonText,
  featuredColor,
  isProductPage = false,
  isWineBox
}) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(isProductPage || false);
  const [qty, setQty] = useState<string>('1');
  const [activeId, setActiveId] = useState<string>(variants[0].id);
  const [activePrice, setActivePrice] = useState<string>(variants[0].price.amount);
  const [totalPrice, setTotalPrice] = useState<number | null>(0);
  const [totalDefaultPrice, setTotalDefaultPrice] = useState<number | null>(0);
  const [activeColor, setActiveColor] = useState<string>(featuredColor);
  const [showDefaultPrice, setShowDefaultPrice] = useState<boolean>(false);
  const [priceMultiplier, setPriceMultiplier] = useState<number>(1)

  const [root] = useMatches();
  const selectedLocale = root?.data?.selectedLocale;
  const fetcher = useFetcher();

  const defaultVariant = variants[0];

  const lines = [{merchandiseId: activeId, quantity: Number(qty)}];

  useEffect(() => {
    // let value = Number(qty)
    // if (value < 6) {
    //     setActivePrice(variants[0].price.amount);
    //     setActiveId(variants[0].id);
    // }
    // if (value >= 6 && value < 12) {
    //     let variantNeeded = variants.find((variant) => variant.title === "6")
    //     setActivePrice(variantNeeded?.price.amount || variants[0].price.amount)
    //     setActiveId(variantNeeded?.id || variants[0].id)
    // }
    // if (value >= 12 && value < 24) {
    //     let variantNeeded = variants.find((variant) => variant.title === "12")
    //     setActivePrice(variantNeeded?.price.amount || variants[0].price.amount)
    //     setActiveId(variantNeeded?.id || variants[0].id)
    // }
    // if (value >= 24) {
    //     let variantNeeded = variants.find((variant) => variant.title === "24")
    //     setActivePrice(variantNeeded?.price.amount || variants[0].price.amount)
    //     setActiveId(variantNeeded?.id || variants[0].id)
    // }
    setTotalPrice((Number(qty) * Number(activePrice)))

  }, [qty])

  useEffect(() => {
    setActivePrice(variants[0].price.amount)
    setActiveId(variants[0].id)
  }, []) 
  
  useEffect(() => {
    setTotalPrice((Number(qty) * Number(activePrice)))
    setTotalDefaultPrice((Number(qty) * Number(defaultVariant.price.amount)))
    setShowDefaultPrice(activeId !== defaultVariant.id)
  }, [activePrice])

  const variantClick = (variant: IProductVariantModel) => {
    setActiveId(variant.id);
    setActivePrice(variant.price.amount);
    setPriceMultiplier(Number.isNaN(Number(variant.title.replace("Box of ", ''))) ? 1 : Number(variant.title.replace("Box of ", '')));
    setQty('1');
  };

  const incrementQty = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setQty(prevState => `${Number(prevState) + 1}`);
  };
  const decrementQty = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setQty(prevState => prevState !== "1" ? `${Number(prevState) - 1}` : "1");
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let value = String(Math.abs(Number(event.target.value)))
    setQty(value === "0" ? "1" : value);
  };
  const qtyChangeHandler = useMemo(
    () => changeHandler
  , []);

  let accordionContainerClasses = () : string => {
    return isFormOpen ? "accordion-container active" : "accordion-container"
  }

  const addToCartClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if(!isFormOpen) {
        setIsFormOpen(prevState => !prevState);
    }
  }

  return (
    <ProductFormContainer featuredColor={featuredColor} accordionClientHeight={accordionRef.current ? accordionRef.current.clientHeight : 0}>
      <div ref={accordionRef} className={accordionContainerClasses()}>
        <div className="info">
            {/* <span>{info}</span> */}
            <span>Bottle: {getSymbolFromCurrency(variants[0].price.currencyCode)}{((Number(activePrice) as unknown as number) / priceMultiplier).toFixed(2)}</span>
        </div>
        <div className="qty">
          {
            !isWineBox && (
            <div className="packs">
            <span>Buy in packs and get discounts!</span>
            <div className="buttons">
                {variants.map((variant, index) => {
                return (
                        <button
                        type="button"
                        key={variant.title}
                        onClick={() => variantClick(variant)}
                        className={activeId === variant.id ? "active" : undefined}
                        >
                        {variant.title}
                        </button>
                );
                })}
            </div>
            </div>
            )
          }
            <div className="qty-select">
            <span>Quantity</span>
            <div>
                <button onClick={decrementQty}>-</button>
                <input type="number" value={String(Math.abs(Number(qty)))} min="1" onChange={qtyChangeHandler}/>
                <button onClick={incrementQty}>+</button>
            </div>
            </div>
        </div>
      </div>
      <div className="actions">
        <div className="total">
            {isFormOpen ? <span>Total:</span> : <></>}
            {showDefaultPrice && (<span style={{textDecoration: "line-through"}}>{getSymbolFromCurrency(variants[0].price.currencyCode)}{(priceMultiplier * (Number(defaultVariant.price.amount) as unknown as number) * Number(qty)).toFixed(2)} </span>)}
            <span>  
            {getSymbolFromCurrency(variants[0].price.currencyCode)}{isFormOpen ? Number(totalPrice).toFixed(2) : Number(activePrice).toFixed(2)} 
            </span>
        </div>
        <div className="cart">
            {isFormOpen ? (
              <>

              <fetcher.Form action='/cart' method="post" onSubmit={() => {
                    toast.custom(<Paragraph style={{color: 'white', backgroundColor: '#122A4E', width: 'auto', padding: '20px 30px', textAlign: 'center', top: 0, left: 0, right: 0}}>Bottles added to cart! Cheers!</Paragraph>, {
                      duration: 3000,
                      position: 'top-right',
                      style: {
                        top: 0,
                        left: 0,
                        right: 0,
                      }
                    })
              }}>
                      <input type="hidden" name="cartAction" value={'ADD_TO_CART'} />
                      <input
                        type="hidden"
                        name="countryCode"
                        value={selectedLocale?.country ?? 'US'}
                      />
                      <input type="hidden" name="lines" value={JSON.stringify(lines)} />
                      <button className="addToCart" style={{cursor: 'pointer'}}>
                        <span className="icon" style={{display: 'inline-flex', justifyContent:"center", alignItems:"center", backgroundColor: activeColor}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.7355 13.795L13.3513 2.42167L14.9229 0.851562L28.9919 14.9072L14.9229 28.9628L13.3513 27.3927L24.7394 16.0155L0.882813 16.0155L0.882813 13.795L24.7355 13.795Z" fill="white"/>
                            </svg>
                        </span>
                        <span>{buttonText}</span>
                      </button>
              </fetcher.Form>
              </>
            ) : (
              <button className="addToCart" onClick={addToCartClickHandler} style={{cursor: 'pointer'}}>
              <span className="icon" style={{display: 'inline-flex', justifyContent:"center", alignItems:"center", backgroundColor: activeColor}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.7355 13.795L13.3513 2.42167L14.9229 0.851562L28.9919 14.9072L14.9229 28.9628L13.3513 27.3927L24.7394 16.0155L0.882813 16.0155L0.882813 13.795L24.7355 13.795Z" fill="white"/>
                  </svg>
              </span>
              <span>{buttonText}</span>
              </button>
            )}
        </div>
      </div>
    </ProductFormContainer>
  );
};
