import { createClient } from "@sanity/client";
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
    projectId: "uxp9q50m",
    dataset: 'production',
    useCdn: false,
    apiVersion: "2021-10-21",
})

const builder = imageUrlBuilder(client);

export const sanityFetch = async (query: string) => {
    return await client.fetch(query);
}

export const urlFor = (source: string) => {
    return builder.image(source).url()
}