import { HeadingContainer } from "~/styles";
import { animated, useInView } from "@react-spring/web";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon} from 'react-share';
import { useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";

export const PageHeading: React.FC<{content: {title: string, ctaLabel: string, ctaText: string, showSocials: boolean}, keyVal: string}> = ({content: {title, ctaLabel, ctaText, showSocials}, keyVal}) =>  {

    const [h1Ref, h1springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 30
            },
            to: {
                opacity: 1,
                y: 0
            },
            config: {
                tension: 300,
            }
        }),
        {
            once: true,
        }
    )
    const [divRef, divsprings] = useInView(
        () => ({
            delay: 400,
            from: {
                opacity: 0,
                y: 30
            },
            to: {
                opacity: 1,
                y: 0
            },
            config: {
                tension: 300,
            }
        }),
        {
            once: true,
        }
    )
    const [location, setLocation] = useState<string>('')
    useEffect(() => {
        setLocation(window.location.href);
    }, [])

    return (
        <HeadingContainer key={keyVal}>
            <animated.h1 ref={h1Ref} style={h1springs}>{title}</animated.h1>
            <animated.div ref={divRef} style={divsprings}>
                <h2>{ctaLabel}</h2>
                <p>{ctaText}</p>
                {showSocials && (
                    <div className="socials" style={{marginTop: 20}}>
                        <FacebookShareButton url={location} quote={title}>
                            <FacebookIcon round={true} bgStyle={{fill: '#DFDDD4'}} iconFillColor={"black"}></FacebookIcon>
                        </FacebookShareButton>
                        <TwitterShareButton url={location} title={title}>
                            <TwitterIcon round={true} bgStyle={{fill: '#DFDDD4'}} iconFillColor={"black"}></TwitterIcon>
                        </TwitterShareButton>
                        <LinkedinShareButton url={location} title={title} source={'Lepa Vida Boutique Vinery'}>
                            <LinkedinIcon round={true} bgStyle={{fill: '#DFDDD4'}} iconFillColor={"black"}></LinkedinIcon>
                        </LinkedinShareButton>
                    </div>
                )}
            </animated.div>
        </HeadingContainer>
    )
}
