import { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { toRem, breakpoint } from './styledUtilities';

export const ProductSellectionContainer = styled.section<{isRight: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 24px;
    ${breakpoint(768, css`
        padding: 0 30px;
        align-items: center;
        gap: 55px;
    `)}
    ${breakpoint(1200, css`
        padding-top: 0;
        padding-bottom: 0;
    `)}
    ${breakpoint(1366, css`
        gap: ${toRem(164)}
    `)}
    ${
        props => props.isRight ? 
        breakpoint(768, css`flex-direction: row-reverse; justify-content: flex-end;`):
        breakpoint(768, css`flex-direction: row; justify-content: flex-start;`)
    }
    ${
        props => props.isRight ? 
        breakpoint(1200, css`padding-left: 100px;`):
        breakpoint(1200, css`padding-right: 100px;`)
    }
    ${
        props => props.isRight ? 
        breakpoint(1366, css`padding-left: ${toRem(100)};`):
        breakpoint(1366, css`padding-right: ${toRem(100)};`)
    }
`

export const ProductImagesContainer = styled.div<{featuredColor: string, onMouseMove: MouseEventHandler}>`
    position: relative;
    &:hover {
        .bcg {
            path {
                fill: ${props => props.featuredColor}
            }
        }
        .cursor {
            opacity: 1;
        }
        img {
            opacity: 0;
        }
        .color {
            opacity: 1;
        }
        cursor: none;
    }
    .cursor {
        position: absolute;
        opacity: 0;
        padding: 10px 20px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        gap: 7px;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        background-color: #EAE8E0;
        border: none;
        transition: opacity .2s ease-in-out;
        z-index: 4;
        pointer-events: none;
        transform: translate(-50%, -50%);
        span {
            white-space: nowrap;
        }
    }
    img {
        position: absolute;
    }
    .bottle {
        width: 76px;
        z-index: 2;
        opacity: 1;
        transition: opacity .2s ease;
        &.color {
            opacity: 0;
            left: 0;
            top: 0;
            height: 100%;
            width: auto;
        }
        &:hover {
            opacity: 0;
            &.color {
                opacity: 1;
            }
        }
    }
    .bcg {
        position: static;
        width: 100%;
        height: auto;
        z-index: 1;
        path {
            transition: fill .2s ease-in-out;
        }
    }
    ${breakpoint(768, css`
    /* flex-grow: 1; */
    max-width: 50%;

    flex-shrink: 1;
        .bottle {
            width: 150px;
            bottom: 20px;
        }
    `)}
    ${breakpoint(1200, css`
    /* flex-grow: 1; */
    max-width: 50%;

    flex-shrink: 1;
        .bottle {
            width: 150px;
            bottom: 20px;
        }
    `)}
    ${breakpoint(1366, css`
    max-width: 50%;
        .bottle {
            width: ${toRem(150)};
            bottom: ${toRem(20)};
        }
    `)}
`

export const ProductInfoContainer = styled.div`
    /* max-width: 344px; */
    h2 {
        font-family: 'Belluga';
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 20px;
        ${breakpoint(1366, css`            
            font-size: ${toRem(50)};
            line-height: ${toRem(50)};
        `)}
    }
    p {
        font-family: 'General Sans';
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        ${breakpoint(1366, css`            
            font-size: ${toRem(14)};
            line-height: ${toRem(24)};
        `)}
    }
    ${breakpoint(1200, css`
        max-width: 468px;
    `)}
    ${breakpoint(1366, css`
        max-width: 50%;
        flex-grow: 1;
    `)}
`

export const ProductFormContainer = styled.div<{featuredColor: string, accordionClientHeight: number}>`
    .accordion-container {
        transition: max-height .4s ease-in-out;
        max-height: 0;
        overflow: hidden;
        &.active {
            max-height: 500px;
        }
    }
    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'General Sans';
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;

        ${breakpoint(1366, css`            
            font-size: ${toRem(20)};
            line-height: ${toRem(26)};
            `)}
    }
    .qty {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;
        ${breakpoint(1366, css`
            flex-direction: row;
            gap: 0;
        `)}
        padding: 20px 0;
        border-top: 2px solid #B2B2B2;
        border-bottom: 2px solid #B2B2B2;
        .packs {
            flex-grow: 1;
        }
        span {
            display: inline-block;
            margin-bottom: 15px;
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            ${breakpoint(1366, css`            
            font-size: ${toRem(14)};
            line-height: ${toRem(24)};
            `)}
        }
        .buttons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            button {
                background: transparent;
                border: 1px solid ${props => props.featuredColor};
                color: ${props => props.featuredColor};
                border-radius: 18px;
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                padding: 9px 12px;
                transition: background-color .2s ease-in-out;
                &.active {
                    background-color: ${props => props.featuredColor};
                    color: white;
                }
                ${breakpoint(1366, css`            
                font-size: ${toRem(14)};
                line-height: ${toRem(24)};
                `)}
            }
        }
        .qty-select {
            flex-shrink: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            span {
                margin: 0;
            }
            ${breakpoint(1366, css`
                flex-direction: column;
            `)}
            div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                input {
                    background-color: transparent;
                    border: none !important;
                    width: 28px;
                    font-family: 'General Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    ${breakpoint(1366, css`            
                    font-size: ${toRem(14)};
                    line-height: ${toRem(24)};
                    `)}
                }
                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }
                button {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    background-color: #DFDDD4;
                }
            }
        }
    }
    .actions {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span, div {
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            ${breakpoint(1366, css`            
            font-size: ${toRem(14)};
            line-height: ${toRem(24)};
            `)}
        }
        .total {
            span {
                font-size: 17px !important;
                line-height: 24px !important;
                display: inline-block;margin-right: 10px;
                ${breakpoint(1366, css`
                    font-size: 25px !important;
                    line-height: 32px !important;
                `)}
            }
        }
        .cart {
            span {
                color: #000000;
                opacity: 0.4;
            }
            form {
                button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    border: none;
                    background-color: transparent;
                    span.icon {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        svg {
                            width: 13px;
                            height: auto;
                        }
                    }
                    span {
                        margin-bottom: 0;
                        opacity: 1;
                    }
                }
            }
            button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                border: none;
                background-color: transparent;
                span.icon {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    svg {
                        width: 13px;
                        height: auto;
                    }
                }
                span {
                    margin-bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
`