import { useParallax } from "react-scroll-parallax"
import { BannerContainer } from "~/styles/WinesBanner"
import { animated, useInView } from "@react-spring/web";
import { urlFor } from "~/utils/api/sanity";

//TODO: Refactor to have the background lines to be part of the background, not absolute elements

export const WinesBanner: React.FC<{module: any, keyVal: string}> = ({ module: { bcgGreen, bcgRed, bottles }, keyVal}) => {
    // const {hygraphData: {data: {ourWines: {bannerImages}}}} = useLoaderData();
    
    const [ref, style] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 30
            },
            to: {
                opacity: 1,
                y: 0
            },
            config: {
                tension: 300,
            }
        }),
        {
            once: true,
        }
    )
    const {ref: ref1} = useParallax<HTMLDivElement>({
        rotate:[60, -29.17],
        translateX: [-30, 15],
        speed: -20,
    })
    const {ref: ref2} = useParallax<HTMLDivElement>({
        speed: -20,
        rotate: [238, 0],
    })
    const {ref: ref3} = useParallax<HTMLDivElement>({
        speed: -20,
        rotate: [-71, 35]
    })

    const paralaxRefs: {[key: string]: React.RefObject<HTMLDivElement>} = {
        "bottle1": ref1,
        "bottle2": ref2,
        "bottle3": ref3,
    }
    
    return (
        <animated.div key={keyVal} ref={ref} style={style}>
        <BannerContainer>  
            {bottles.map((img: {_type: string, _key: string, asset: { _ref: string, _type: string }}) => (
                <div ref={paralaxRefs[img._type]} data-name={img._type} key={img._key}>
                    <img src={urlFor(img.asset._ref)} data-name={img._type} alt={img._type}/>
                    <img src={urlFor(img.asset._ref)} className="shadow"/>
                </div>
                ))}
                <img src={urlFor(bcgRed.asset._ref)} data-name={bcgRed._type+'red'} alt={bcgRed._type}/>
                <img src={urlFor(bcgGreen.asset._ref)} data-name={bcgGreen._type+'green'} alt={bcgGreen._type}/>
        </BannerContainer>
        </animated.div>
    )
    
}