import { ReactNode, Suspense, useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { Header, Footer } from './';
import {Drawer, useDrawer} from '~/components/shared/Drawer';
import { useMatches } from "react-router";
import {CartLineItems, CartActions, CartSummary} from '~/components/core/Cart';
import { Await, Form, Link, useLoaderData } from "@remix-run/react";
import styled, { css } from "styled-components";
import MenuImage from '../assets/images/lepaVida-menu-img.webp'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Hamburger, Logo, StyledHeader } from "~/styles";
import MenuExit from '../assets/images/Menu-exit.svg'
import {Toaster} from 'react-hot-toast'
import { urlFor } from "~/utils/api/sanity";
import { NavLink } from "~/styles/HeaderStyles";
import { breakpoint } from "~/styles/styledUtilities";
import { ActionFunction, LoaderFunction, redirect } from "@shopify/remix-oxygen";
import { gdprConsent } from "~/cookies";
import { Paragraph } from "~/utils/helpers/mapModulesToUi";


const CartEmpty = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        margin-bottom: 20px;
        font-family: "General Sans";
    }
    p {
        max-width:75%;
        font-family: "General Sans";
        text-align: center;
    }
`


const ConsentButton = styled.button `
    padding: 10px; 
    border: 1px solid rgb(22, 64, 93);
    background-color: rgb(22, 64, 93);
    color: white;
    transition: color .4s ease, background-color .4s ease;
    border-radius: 6px;
    margin-top: 20px;
    &:hover {
        color: rgb(22, 64, 93);
        background-color: transparent;
    }
`

const Navigation = styled.div<{menuImage:string | undefined}>`
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${props => props.menuImage ? props.menuImage : MenuImage});
    background-size: cover;
    background-position: center;
    z-index: 100;
    display: flex;
    flex-direction: column;
    .nav {
        position: relative;z-index: 102;
        .nav-x {
            width: auto;
            margin-right: auto;
            img {
                height: 40px;
            }
        }
        .nav-logo {
            color: white;
        }
    }
    .redSvg {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 75%;
        z-index: 101;
    }
    .blueSvg {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 101;
    }
    nav {
        padding: 30px;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        position: relative; z-index: 102;
        .navlinks {
            display: block;
            border-bottom: 3px solid white;
            margin-bottom: 20px;
            color: white;
            line-height: 150%;
            opacity: .5;
            transition: opacity .2s ease;
            &:hover {
                opacity: 1;
            }
            cursor: pointer;
            width: 100%;
            text-align: center;
        }
        ${breakpoint(768, css`
            justify-content: flex-end;
            .navlinks {
                text-align: left;
                max-width: 75%;
            }
        `)}
        ${breakpoint(1368, css`
            .navlinks {
                max-width: 60%;
            }
        `)}
    }
`




export const Layout: React.FC<{children: ReactNode}> = ({children}) => {
    const [isDrawerOpen, openDrawer, closeDrawer] = useDrawer();
    const [isNavOpen, openNav, closeNav] = useDrawer();
    const [root] = useMatches();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cart = root.data?.cart
    // @ts-ignore
    const links = root.data?.linkSlugs
    // @ts-ignore
    const menuImageRef = root.data?.menuImageRef
    // @ts-ignore
    const showGdprBanner = root.data?.showGdprBanner;
    
    return (
    <ParallaxProvider>
        <Toaster></Toaster>
        <Drawer open={isDrawerOpen} closeFnc={closeDrawer}>
            <Suspense>
                <Await resolve={cart}>
                    {(data) => (
                        <>
                        {data?.totalQuantity > 0 ? (
                            <>
                                <CartLineItems linesObj={data.lines}/>
                                <CartSummary cost={cart.cost}></CartSummary>
                                <CartActions checkoutUrl={cart.checkoutUrl}></CartActions>
                            </>
                        ) : (
                            <CartEmpty>
                                <h2>Cart Empty</h2>
                                <p>Feel free to browse the store and chose a wine to your taste.</p>
                            </CartEmpty>
                        )}
                        </>
                    )}
                </Await>
            </Suspense>
        </Drawer>
        <Header drawerOpen={openDrawer} cart={cart} navOpen={openNav}></Header>
        {isNavOpen && (
            <Navigation menuImage={urlFor(menuImageRef)}>
                <StyledHeader className="nav">
                    <Hamburger onClick={closeNav as any} className="nav-x" style={{cursor: 'pointer'}}>
                        <img src={MenuExit} alt="" />
                    </Hamburger>
                    <Logo to={"/"} className="nav-logo">Lepa Vida</Logo>
                    <Hamburger className="nav-x">
                        <img src={MenuExit} alt=""  style={{visibility: "hidden"}}/>
                    </Hamburger>
                </StyledHeader>
                <nav>
                    {links.map((link: any, index: number) => 
                        <NavLink key={index} to={`/${link.slug}`} className="nav-x navlinks" onClick={closeNav as any}>
                            {link.title}
                        </NavLink>
                    )}
                </nav>
            </Navigation>
        )}
        
        <main role="main" id="mainContent" style={{overflow: "hidden"}}>{children}</main>
        <Footer></Footer>
        {showGdprBanner && (
        <div
          style={{
            backgroundColor: "#F0F0F0",
            padding: 10,
            position: "fixed",
            width: '100vw',
            height: 'auto',
            bottom: 0,
            zIndex: 100000,
          }}
        >
          {/* Reload the document to be able to enable the tracking script on the root */}
          <Form method="post" reloadDocument>
            <Paragraph>
                <b>

            This website uses cookies
                </b>
                <br/>
            We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services.
            {/* You can pass values on the submission button  */}
            <span style={{display: "block"}}>
            <ConsentButton name="accept-gdpr" value="true" type="submit">
              Accept
            </ConsentButton>
            </span>
            </Paragraph>
          </Form>
        </div>
      )}
    </ParallaxProvider>
    )
}