import { animated, useInView } from "@react-spring/web";
import { Link } from "@remix-run/react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { breakpoint, toRem } from "~/styles/styledUtilities";
import { urlFor } from "~/utils/api/sanity";

interface AboutUsPropType {
    module: {
        Image: {
            _type: string,
            asset: {
                _type: string,
                _ref: string,
            }
        },
        color: {
            hex: string,
        }
        _key: string,
        Description: string,
        _type: string,
        bellugaTitle: string
        generalSansTitle: string
        linkUrl: string,
        linkText: string,
        isRight: any;
        label: string;
    }
    keyVal: string;
}

const AboutUsContainer = styled.section<{isRight: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .subTitle {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 25px;
        ${breakpoint(1366, css`
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 40px;
        `)}
    }
    img {
        width: 100%;
    }
    gap: 42px;
    padding: 0 20px;
    h2 {
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 20px;
        width: 50%;
    }
    p {
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    a {
        color: black;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        font-family: "General Sans";
        margin-top: 20px;
        span {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: black;
            svg {
                width: 50%;
                height: auto;
                path {
                    fill: white;
                }
            }
        }
    }
    // @ts-ignore
    ${breakpoint(768, css`
        padding: 0 30px;
        ${props => props.isRight ? 
            css`flex-direction: row-reverse;` :
            css`flex-direction: row;` as FlattenSimpleInterpolation
        }
        justify-content: space-between;
        img {
            width: 41%;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
            width: 65%;
        }
        div {
            width: 50%;
        }
    `)}
    ${breakpoint(1200, css`
        padding: 0px 100px;
        img {
            width: 50%;
        }
        div {
            width: 41%;
        }
        h2 {
            font-size: 60px;
            line-height: 75px;
            width: 75%;
        }
    `)}
    ${breakpoint(1366, css`
        padding: 0px ${toRem(100)} 0px ${toRem(100)};
        img {
            width: 50%;
        }
        div {
            width: 41%;
        }
        h2 {
            font-size: ${toRem(60)};
            line-height: ${toRem(75)};
            width: 70%;
        }
        p {
            font-size: ${toRem(14)};
            line-height: ${toRem(24)};
        }
    `)}
`

export const AboutUs: React.FC<AboutUsPropType> = ({module, keyVal}) => {
    const {Image, Description, bellugaTitle, generalSansTitle, linkUrl, linkText, color, isRight, label} = module;
    // console.log(isRight);
    const [ref, style] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 30
            },
            to: {
                opacity: 1,
                y: 0
            },
            config: {
                tension: 300,
            }
        }),
        {
            once: true,
        }
    )
    return (
        <animated.div ref={ref} style={style} key={keyVal}>
            <AboutUsContainer isRight={isRight || false}>
            <img src={urlFor(Image.asset._ref)} alt="" />
            <div>
                {bellugaTitle && (<h2 className="belluga" style={{color: color ? color.hex : "black"}}>{bellugaTitle}</h2>)}
                {generalSansTitle && (<h2 className="generalSans">{generalSansTitle}</h2>)}
                {label && <p className="subTitle">{label}</p>}
                {Description && (
                <p dangerouslySetInnerHTML={{__html: Description}}></p>
                )}
                {(linkUrl && linkText) && (
                    <Link to={linkUrl}>
                        <span style={{backgroundColor: color ? color.hex : "black"}}>
                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4312 13.7549L13.0482 2.38261L14.6198 0.8125L28.6888 14.8681L14.6198 28.9238L13.0482 27.3537L24.4373 15.9753H0.578125V13.7549H24.4312Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        {linkText}
                    </Link>
                )}
            </div>
            </AboutUsContainer>
        </animated.div>
    )

}