import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

export function breakpoint(bp: number, cssString: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
    return css`
        @media (min-width: ${bp}px) {
            ${cssString}
        }
    `
}

export function toRem(sizeInPixels: number): FlattenSimpleInterpolation {
    let coef = sizeInPixels/1366;
    return css`${coef*100}vw`;
}