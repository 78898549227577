import { useParallax } from "react-scroll-parallax"
import { ProductImagesContainer, ProductInfoContainer, ProductSellectionContainer } from "~/styles"
import { IOurWinesProduct } from "~/utils/types/ourWines"
import { ProductForm } from "../shared/ProductForm"
import SVG from "react-inlinesvg";
import { useRef, MouseEvent } from "react";
import { Link } from "@remix-run/react";



export const ProductSelection: React.FC<{product: IOurWinesProduct, isRight: boolean, featuredColor: string, longDescription?: string, isStaticImage?: boolean, keyVal: string, isProductPage?: boolean}> = ({product: {images, variants, description, title, info, handle, tags}, isRight, featuredColor, longDescription, isStaticImage, keyVal, isProductPage}) => {
    // const {ref} = useParallax<HTMLImageElement>(isRight ? {
    //     translateY:[0, 0],
    //     translateX: [300, 0],
    //     rotate:[45, -45],
    //     speed: -40,
    //     disabled: isStaticImage
    // } : {
    //     translateY:[0, 0],
    //     translateX: [0, 300],
    //     rotate:[-45, 45],
    //     speed: -40,
    //     disabled: isStaticImage

    // })

    const imagesContainerRef = useRef<HTMLDivElement>(null);
    const viewMoreRef = useRef<HTMLButtonElement>(null);

    const mouseOverHandler = (event: MouseEvent) => {
        event.stopPropagation();
        let rect = imagesContainerRef.current?.getBoundingClientRect();
        viewMoreRef.current && (viewMoreRef.current.style.left = event.clientX - (rect?.left || 0) + "px")
        viewMoreRef.current && (viewMoreRef.current.style.top = event.clientY - (rect?.top || 0) + "px")
    }
    

    return (
        <ProductSellectionContainer key={keyVal} isRight={isRight}>
            <ProductImagesContainer featuredColor={featuredColor} onMouseMove={mouseOverHandler} ref={imagesContainerRef}>
                <Link to={`/product/${handle}`}>
                <img className="bottle" src={images.nodes[isProductPage && images.nodes[1]  ?  1 : 0].url} alt="" style={isStaticImage ? isProductPage ? {width: '100%', height: 'auto', position: 'static', opacity: 1} : {width: '100%', height: 'auto', position: 'static'} : {}}/>

                {images.nodes[1] && !isProductPage && <img className="bottle color" src={images.nodes[1].url} alt="" style={isStaticImage ? {width: '100%', height: 'auto', position: 'absolute'} : {}}/>}
                {/* {images.nodes[1] && <SVG className="bcg" src={images.nodes[1].url}/>} */}
                {!isProductPage && (
                <button className="cursor" ref={viewMoreRef}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M11.5234 11.2187L20.875 11.2188L20.875 9.91797L11.5234 9.91797L11.5234 0.570312L10.2266 0.570312L10.2266 9.91797L0.875 9.91797L0.875 11.2187L10.2266 11.2187L10.2266 20.5703L11.5234 20.5703L11.5234 11.2187Z" fill={featuredColor}/>
                        </svg>
                    </span>
                    <span>
                        View More
                    </span>
                </button>
                )}
                </Link>
            </ProductImagesContainer>
            <ProductInfoContainer>
                <h2 style={{color: featuredColor}}>{title}</h2>
                {isProductPage ? (
                    <>
                    <ProductForm isWineBox={tags?.includes('wine box') || false} isProductPage={true} variants={variants.nodes} showPrice={true} info={info ? info.value : ""} buttonText={'Add to cart'} featuredColor={featuredColor}/>
                    <p dangerouslySetInnerHTML={{ __html: longDescription ? longDescription.replace('\n', '<br/>') : description }}></p>
                    </>
                ) : (
                    <>
                    <p dangerouslySetInnerHTML={{ __html: longDescription ? longDescription.replace('\n', '<br/>') : description }}></p>
                    <ProductForm isWineBox={tags?.includes('wine box') || false} isProductPage={false} variants={variants.nodes} showPrice={true} info={info ? info.value : ""} buttonText={'Add to cart'} featuredColor={featuredColor}/>
                    </>
                )}
            </ProductInfoContainer>
        </ProductSellectionContainer>
    )
}