import styled, { css } from 'styled-components';
import { toRem, breakpoint } from './styledUtilities';

export const BannerContainer = styled.section`
    background: linear-gradient(175.38deg, #DEDCD3 54.8%, #C2BBA3 86.18%, #DEDBD3 110%);
    width: 100%;
    height: 180px;
    position: relative;
    overflow: hidden;
    div[data-name="bottle1"], div[data-name="bottle2"],div[data-name="bottle3"]{
        position: absolute;
        z-index: 3;
        img {
            width: 110px;
            position: relative;
            z-index: 4;
        }
        .shadow {
            position: absolute;
            left: 30px;
            z-index: 3;
            filter: blur(9px) brightness(70%);
        }
    }
    div[data-name="bottle1"] {
        left: -15px;
        bottom: 0px;
        transform: rotate(29.17deg);
    }
    div[data-name="bottle2"] {
        left: calc(50% - 65px);
        top: 0px;
        transform: rotate(117.93deg);
    }
    div[data-name="bottle3"] {
        right: 40px;
        top: 20px;
        transform: rotate(-35.98deg);
    }
    img[data-name="imagered"] {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 75%;
        z-index: 1;
    }
    img[data-name="imagegreen"] {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 2;
    }
    ${breakpoint(768, css`
        height: 365px;
        div[data-name="bottle1"], div[data-name="bottle2"],div[data-name="bottle3"]{
        img {
            width: 220px;
        }
        .shadow {
            left: 30px;
        }
    }
    `)}
    ${breakpoint(1200, css`
        height: 571px;
        div[data-name="bottle1"], div[data-name="bottle2"],div[data-name="bottle3"]{
        img {
            width: 320px;
        }
        .shadow {
            left: 30px;
        }
    `)}
    ${breakpoint(1365, css`
        height: ${toRem(650)};
        div[data-name="bottle1"], div[data-name="bottle2"],div[data-name="bottle3"]{
        img {
            width: ${toRem(400)};
        }
        .shadow {
            left: ${toRem(50)};
        }
    `)}
`