import { CartIcon, Hamburger, Logo, StyledHeader } from "~/styles/HeaderStyles";
import CartIconSvg from "../../assets/images/CartIcon.svg"
import {Suspense, useEffect} from 'react';
import {Await, Link} from '@remix-run/react';
import { sanityFetch } from "~/utils/api/sanity";
import { ISanityData } from "~/utils/types/sanity";

export const Header = ({cart, drawerOpen, navOpen}: any) => {
    return(
        <StyledHeader>
            <Hamburger onClick={navOpen} style={{cursor: 'pointer'}}>
                <div></div>
                <div></div>
                <div></div>
            </Hamburger>
            <Logo to={'/'}>Lepa Vida</Logo>
            <Suspense>
                <Await resolve={cart}>
                    {(data) => (
                    <CartIcon onClick={drawerOpen}>
                        <img src={CartIconSvg} alt="" />
                        <span>{data?.totalQuantity}</span>
                    </CartIcon>
                    )}
                </Await>
            </Suspense>
        </StyledHeader>
    )
}
