import styled, { createGlobalStyle, css } from "styled-components";
import Faxi from '../assets/fonts/Faxi/Faxi-Regular.woff'
import Faxi2 from '../assets/fonts/Faxi/Faxi-Regular.woff2'
import GeneralSans from '../assets/fonts/GeneralSans/GeneralSans-Medium.woff'
import GeneralSans2 from '../assets/fonts/GeneralSans/GeneralSans-Medium.woff2'
import Belluga from '../assets/fonts/Belluga/Belluga.otf'
import { breakpoint, toRem } from "./styledUtilities";

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    body {
        background-color: #F0F0F0;
    }
    a {
        cursor: pointer;
    }

    @font-face {
        font-family: 'Faxi';
        src: url(${Faxi}) format('woff'),
             url(${Faxi2}) format('woff2');
    }

    @font-face {
        font-family: 'General Sans';
        src: url(${GeneralSans}) format('woff'),
             url(${GeneralSans2}) format('woff2');
    }

    @font-face {
        font-family: 'Belluga';
        src: url(${Belluga}) format('opentype');
    }
`

export const Spacer = styled.div`
    width: 100%;
    height: 50px;
    ${breakpoint(1200, css`
        height: 75px;
    `)}
    ${breakpoint(1366, css`
        height: toRem(75);
    `)}
`

export default GlobalStyles;