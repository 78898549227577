import styled, { css } from "styled-components";
import { breakpoint, toRem } from "./styledUtilities";

export const HeadingContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 23px;
    div {
        position: relative;
        transition: transform .4s ease-in-out, opacity .4s ease-in-out;
    }
    h1 {
        font-family: 'Faxi';
        font-style: normal;
        font-weight: 400;
        font-size: 47px;
        line-height: 50px;
        position: relative;
        transition: transform .4s ease-in-out, opacity .4s ease-in-out;
    }
    h2 {
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 16px;
    }
    p {
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }
    ${breakpoint(768, css`
        flex-direction: row;
        align-items: center;
        padding: 0 30px;
        h1 {
            flex: 1 1 50%;
        }
        div {
            flex: 1 1 50%;
        }
        h2 {
            font-size: 22px;
            line-height: 28px;
        }
        p {
            font-size: 18px;
            line-height: 24px;
        }
    `)}
    ${breakpoint(1200, css`
        gap: 116px;
        padding: 0 100px 0 100px;
        h1 {
            font-size: 63px;
            line-height: 68px;
        }
        h2 {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 25px;
        }
        p {
            font-size: 18px;
            line-height: 24px;
        }
    `)}
    ${breakpoint(1366, css`
        gap: ${toRem(283)};
        padding: 0 ${toRem(100)};
        h1 {
            font-size: ${toRem(63)};
            line-height: ${toRem(68)};
        }
        h2 {
            font-size: ${toRem(35)};
            line-height: ${toRem(42)};
        }
        p {
            font-size: ${toRem(18)};
            line-height: ${toRem(24)};
        }
    `)}
`