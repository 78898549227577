import {Link, useFetcher} from '@remix-run/react';
import {flattenConnection, Image, Money} from '@shopify/hydrogen-react';
import styled from 'styled-components';
import { IconClose } from '../shared/Drawer';


export function CartLineItems({linesObj}: any) {
  const lines = flattenConnection(linesObj);
  return (
    <div style={{flexGrow:1}}>
      {lines.map((line, index) => {
        return <LineItem key={index} lineItem={line} />;
      })}
    </div>
  );
}

const LineItemContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  font-family: "General Sans";
  .title {
    color: black;
    text-decoration: none;
  }
  .remove-btn {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    button {
      border: none;
      background-color: transparent;
    }
  }
`



function LineItem({lineItem}: any) {
  const {merchandise, quantity} = lineItem;
  const fetcher = useFetcher();
  const lineIds = [lineItem.id];
  
  return (
    <LineItemContainer>
      <Link
        to={`/products/${merchandise.product.handle}`}
        className="flex-shrink-0"
      >
        <Image data={merchandise.image} width={50} height={'auto'}/>
      </Link>
      <div className="flex-1">
        <Link
          to={`/products/${merchandise.product.handle}`}
          className="title"
        >
          {merchandise.product.title} ({merchandise.selectedOptions[0].value})
        </Link>
        {/* <div className="title">{merchandise.title}</div> */}
        <div className="qty">Qty: {quantity}</div>
        <Money className="price" data={lineItem.cost.totalAmount} />
      </div>
      <fetcher.Form action="/cart" className="remove-btn" method="post">
      <input type="hidden" name="cartAction" value="REMOVE_FROM_CART" />
      <input type="hidden" name="linesIds" value={JSON.stringify(lineIds)} />
        <button type="submit">
          <IconClose/>
        </button>
      </fetcher.Form>
    </LineItemContainer>
  );
}

const SubTotal = styled.div`
  margin: 20px;
  font-family: "General Sans";
  div {
    display: inline;
  }
`

export function CartSummary({cost}: any) {
    return (
      <SubTotal>
      <span>Subtotal : </span>
      <span>
        {cost?.subtotalAmount?.amount ? (
          <Money data={cost?.subtotalAmount} />
        ) : (
          '-'
        )}
      </span>
    </SubTotal>
    );
  }

  const CheckoutBtn = styled.a`
    color: white;
    background-color: black;
    border-radius: 6px;
    border: none;
    text-decoration: none;
    padding: 15px 0;
    display: block;
    margin: 0 20px 20px;
    font-family: "General Sans";
    text-align: center;
  `

  export function CartActions({checkoutUrl}: any) {
    if (!checkoutUrl) return null;
  
    return (
        <CheckoutBtn
          href={checkoutUrl}
        >
          Continue to Checkout
        </CheckoutBtn>
    );
  }